<template>
  <div>
    <v-row justify-center>
      <v-col
        cols="12"
        md="4"
        @click="invokeFileUploader"
        class="grey py-4 text-center"
        style="cursor:pointer"
      >
        <div v-if="!loading">
          <v-icon large>mdi-file-upload</v-icon>
          <input
            type="file"
            @input="handleFileInput"
            style="display:none"
            name
            id
            ref="fileInput"
            multiple
          />
          <br />
          <h4>Upload Images</h4>
          <h6>({{files.length}}) files uploaded</h6>
        </div>
        <div v-else>
          <v-progress-circular indeterminate color="purple" :size="100"></v-progress-circular>
        </div>
      </v-col>
      <v-col cols="12"></v-col>
      <v-col cols="6" md="2" sm="4" v-for="(v,i) in files" :key="i">
        <div class="image_container" :style="{'background-image':`url(${v})`}">
          <v-icon @click="deleteImage(v,i)">mdi-close</v-icon>
        </div>
      </v-col>
    </v-row>
    <div></div>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  props: ["images"],
  data() {
    return {
      loading: false,
      files: [],
      isImageChecked: false,
    };
  },
  watch: {
    
    loading(val) {
      this.$emit("updateLoadingStatus", val);
    },
    files(val) {
      this.$emit("filesUploaded", val);
    },
  },
  methods: {
    invokeFileUploader() {
      this.$refs.fileInput.click();
    },
    uploadAndReturnLink(file) {
      this.loading = true;
      var _ = this;
      return new Promise((resolve, reject) => {
        var storageRef = firebase
          .storage()
          .ref(`services/ ${Math.random()}ext$`);
        let uploadTask = storageRef.put(file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            alert("an error occureed when uploading the image ");
            _.loading = false;
            reject({ status: "error", data: error.message });
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              _.loading = false;
              resolve({ status: "success", data: downloadURL });
            });
          }
        );
      });
    },
    deleteImage(imgURL, i) {
      this.loading = true;

      let image = null;
      var fb = firebase;
      image = fb.storage().refFromURL(imgURL);
      var _ = this;
      image
        .delete()
        .then(function () {
          _.files.splice(i, 1);
          _.loading = false;
        })
        .catch(function (error) {
          // Uh-oh, an error occurred!
          _.loading = false;
        });
    },

    async handleFileInput(e) {
      let files = e.target.files;
      console.log("files to be uploaded", files.length);
      console.log("files to be uploaded", files);
      for (var file of files) {
        console.log(file);
        let res;
        try {
          res = await this.uploadAndReturnLink(file);
          if (res.status == "success") {
            this.files.push(res.data);
            this.loading = false;
          } else {
            alert("errorr in puuteed" + res.data);
            //handle error
          }
        } catch (err) {
          alert(err + "in cathc");
        }
      }
    },
  },
  mounted() {
    this.files = this.images;
  },
};
</script>

<style>
.image_container {
 /* max-width: 200px;
*/  height: 150px;
  background-size: contain;
  border-radius: 3px;
  border: 1px solid black;
}
</style>
